import React, { type FC, useContext, useState } from 'react'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, type Theme, Typography } from '@mui/material'
import axios from 'axios'
import ConfigContext from '../ConfigContext/Config'
import { PhoneNumber } from '../../classes/PhoneNumber'
import { styled } from '@mui/system'

interface Ticket {
  Company: string
  Priority: string
  RequestTicket: string
  Site: string
  Status: string
  Summary: string
}

interface IncidentListProps {
  formData: any
  tickets: Ticket[]
  setIsLoading: any
}

const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper // Use the theme's background color
}))

const IncidentList: FC<IncidentListProps> = ({ formData, tickets, setIsLoading }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(3)
  const { properties } = useContext(ConfigContext)
  const directlineUrl = properties.baseUrls.directline + '/api/saas/' + properties.directline.key

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <TableContainer style={{ height: '100%', width: '100%', overflow: 'auto' }} >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Customer Tickets</StyledTableCell>
              <StyledTableCell>Summary</StyledTableCell>
              <StyledTableCell>Priority</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.length > 0
              ? (
                  tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ticket, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <a
                      href="#"
                      onClick={(e) => {
                        setIsLoading(true)
                        e.preventDefault()
                        axios
                          .post(`${directlineUrl}/directline/event`, {
                            name: 'displayIncident',
                            data: {
                              incidentNumber: ticket.RequestTicket
                            },
                            userId: formData.contact
                          })
                          .then((response) => {
                            console.log(response)
                          })
                          .catch((error) => {
                            console.error('Error sending event:', error)
                          })
                      }}
                    >
                      {ticket.RequestTicket}
                    </a>
                  </TableCell>
                  <TableCell>{ticket.Summary}</TableCell>
                  <TableCell>{ticket.Priority}</TableCell>
                  <TableCell>{ticket.Status}</TableCell>
                </TableRow>
                  ))
                )
              : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No tickets available
                </TableCell>
              </TableRow>
                )}
          </TableBody>
        </Table>
        {tickets.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[3]}
            component="div"
            count={tickets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  )
}

export default IncidentList
