import React, { type FC, useContext, useEffect, useState } from 'react'
import { Container, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, Snackbar } from '@mui/material'
import './AdminNotifications.module.css'
import HelpIcon from '@mui/icons-material/Help'
import DeleteIcon from '@mui/icons-material/Delete'
import { createNotification, deleteNotification, fetchAllNotifications } from '../../services/NotificationService'
import ConfigContext from '../ConfigContext/Config'

interface AdminNotificationsProps { }

const AdminNotifications: FC<AdminNotificationsProps> = () => {
  const { properties } = useContext(ConfigContext)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const [company, setCompany] = useState('')
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const fetchAndSetNotifications = () => {
    fetchAllNotifications(properties.id)
      .then((data) => {
        console.log(data)
        setRows(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchAndSetNotifications()
  }, [])

  const handleOpenSnackbar = (open, message) => {
    setOpenSnackbar(open)
    setSnackbarMessage(message)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleTypeChange = (event) => {
    setType(event.target.value)
  }

  const handleCompanyChange = (event) => {
    setCompany(event.target.value)
  }

  const handleCreateNotification = () => {
    const notification = {
      pk: properties.id,
      title,
      message,
      type,
      customerCompany: company
    }

    createNotification(notification)
      .then((response) => {
        fetchAndSetNotifications()
        handleOpenSnackbar(true, 'Notification created successfully!')
        handleClose()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleDeleteNotification = (pk, sk) => {
    console.log(pk, sk)
    deleteNotification(pk, sk)
      .then((response) => {
        fetchAndSetNotifications()
        handleOpenSnackbar(true, 'Notification deleted successfuly!')
      })
      .catch((error) => {
        // Handle error
        console.error(error)
      })
  }

  return (
    <div>
      <Container maxWidth="lg" style={{ marginTop: '16px' }}>
        <Box display="flex" justifyContent="flex-start" marginBottom="16px" bgcolor="grey.100" padding="16px">
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            New Notification
          </Button>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Last Modified</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.createdDate}</TableCell>
                  <TableCell>{row.lastModified}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => { handleDeleteNotification(row.PK, row.SK) }}>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Notification</DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <Box display="flex" alignItems="center">
            <TextField autoFocus margin="dense" id="title" label="Title" type="text" fullWidth value={title}
              onChange={(e) => { setTitle(e.target.value) }} />
            <Tooltip title="The title of the Notification">
              <IconButton size="small" style={{ marginLeft: 8 }}>
                <HelpIcon fontSize="small" color='primary' />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            <TextField
              margin="dense"
              id="message"
              label="Message"
              type="text"
              fullWidth
              multiline
              rows={4}
              inputProps={{ maxLength: 1000 }}
              value={message}
              onChange={(e) => { setMessage(e.target.value) }}
            />
            <Tooltip title="The description of the notification, the important information that agents need to know">
              <IconButton size="small" style={{ marginLeft: 8 }}>
                <HelpIcon fontSize="small" color='primary' />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth margin="dense">
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                id="type"
                value={type}
                label="Type"
                onChange={handleTypeChange}
              >
                <MenuItem value={'warning'}>Warning</MenuItem>
                <MenuItem value={'general'}>General</MenuItem>
                <MenuItem value={'critical'}>Critical</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="The severity of the notification">
              <IconButton size="small" style={{ marginLeft: 8 }}>
                <HelpIcon fontSize="small" color='primary' />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth margin="dense">
              <InputLabel id="company-label">Company</InputLabel>
              <Select
                labelId="company-label"
                id="company"
                value={company}
                label="Company"
                onChange={handleCompanyChange}
              >
                <MenuItem value={'bcx'}>bcx</MenuItem>
                <MenuItem value={'tigerbrands'}>tigerbrands</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Who the message will be sent to or is referring to">
              <IconButton size="small" style={{ marginLeft: 8 }}>
                <HelpIcon fontSize="small" color='primary' />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateNotification} color="primary" >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => { handleOpenSnackbar(false, '') }}
        message={snackbarMessage}
      />
    </div>
  )
}

export default AdminNotifications
