import React, { useEffect, useState, useContext, useRef } from 'react'
import { DirectLine } from 'botframework-directlinejs'
import ReactWebChat, { createStore } from 'botframework-webchat'
import axios from 'axios'
import configContext from '../ConfigContext/Config'
import './WebChat.css'
import moment from 'moment'
import { PhoneNumber } from '../../classes/PhoneNumber'
import { UserContext } from '../Auth/Auth'

const WebChat = (props: any) => {
  const [directline, setDirectline] = useState<any>(null)
  const [conversation, setConversation] = useState<any>(null)
  const [store, setStore] = useState<any>(null)
  const { properties } = useContext(configContext)
  const { session } = useContext(UserContext)
  const directlineUrl = properties.baseUrls.directline + '/api/saas/' + properties.directline.key
  const [isConnected, setIsConnected] = useState(false)
  // ISO 8601 duration format
  const EXPIRATION_TIME = properties.sessionTimeout.sessionInactivityTimeout ? moment.duration(properties.sessionTimeout.sessionInactivityTimeout).asMilliseconds() : 1 * 60 * 1000
  const expirationTimer = useRef(null)
  const contact = new PhoneNumber(props.formData.contact).getInternationalFormatWithoutPlus()

  const resetTimer = () => {
    if (expirationTimer.current) {
      clearTimeout(expirationTimer.current)
    }

    expirationTimer.current = setTimeout(() => {
      props.onShowTimeoutModal()
    }, EXPIRATION_TIME)
  }

  const uploadFile = async (file) => {
    // Upload the file to your server or storage solution
    // This is just a placeholder, replace with your actual upload logic
    const response = await fetch('/upload', {
      method: 'POST',
      body: file
    })

    const data = await response.json()

    return {
      name: file.name,
      url: data.url
    }
  }

  const handleFileUpload = async (files, dispatch) => {
    // Tells the bot that you are uploading files. This is optional.
    dispatch({ type: 'WEB_CHAT/SEND_TYPING' })

    const uploadedFiles = await Promise.all(files.map(uploadFile))

    // Send an event activity to the bot with the uploaded file URLs
    dispatch({
      type: 'WEB_CHAT/SEND_EVENT',
      payload: {
        name: 'upload',
        type: 'event',
        value: { files: uploadedFiles }
      }
    })
  }

  useEffect(() => {
    // const webchatSpeech = createBrowserWebSpeechPonyfillFactory();
    console.log(props.formData)
    const directLine = new DirectLine({
      domain: `${directlineUrl}/directline`,
      conversationStartProperties: {
        name: props.formData.name,
        value: props.formData.contact
      }
    })

    const store = createStore({}, (dispatcher: any) => (next: any) => (action: any) => {
      try {
        console.debug('Action:', action)

        switch (action.type) {
          case 'DIRECT_LINE/CONNECT_FULFILLED':
            setIsConnected(true)
            break

          case 'DIRECT_LINE/UPDATE_CONNECTION_STATUS':
            const connectionStatuses = ['UNINITIATED', 'CONNECTING', 'ONLINE', 'TOKEN_EXPIRED', 'FAILED_TO_CONNECT', 'ENDED']
            const connectionStatus = connectionStatuses[action.payload.connectionStatus] || 'ERROR_GETTING_CONNECTION_STATUS'
            console.debug(`DIRECT_LINE >> UPDATED_CONNECTION_STATUS: ${connectionStatus}`)
            break

          case 'DIRECT_LINE/DISCONNECT_FULFILLED':
            console.log('disconnected')
            if (conversation) {
              directLine.reconnect({ ...conversation })
            }
            break

          case 'DIRECT_LINE/POST_ACTIVITY_REJECTED':
            console.error('Activity post rejected:', action.payload.error)
            break

          case 'DIRECT_LINE/POST_ACTIVITY':
            const { contact: value, type: login_type } = props.formData
            const { sessionId } = session
            action.payload.activity.channelData = {
              ...action.payload.activity.channelData,
              value,
              login_type,
              sessionId
            }
            break

          case 'DIRECT_LINE/INCOMING_ACTIVITY':
            const { activity } = action.payload

            if (activity.type === 'messageReaction' && activity.attachments && activity.attachments.length > 0) {
              const attachment = activity.attachments[0]
              console.log(attachment)

              if (attachment.contentType === 'application/json' && attachment.name === 'customerPanel') {
                const content = JSON.parse(attachment.content)
                if (Array.isArray(content) && content.length > 0) {
                  props.onCustomerDetails(content[0])
                }
              }

              if (attachment.contentType === 'application/json' && attachment.name === 'incidentListPanel') {
                const content = JSON.parse(attachment.content)
                if (Array.isArray(content) && content.length > 0) {
                  props.onIncidents(content)
                }
              }

              if (attachment.contentType === 'application/json' && attachment.name === 'highPriority') {
                const content = JSON.parse(attachment.content)
                if (Array.isArray(content) && content.length > 0) {
                  props.onHighPriorityIncidents(content)
                }
              }

              if (attachment.contentType === 'application/json' && attachment.name === 'incidentDetailListPanel') {
                const content = JSON.parse(attachment.content)
                const requiredProperties = [
                  'Number',
                  'WorkLogNumber',
                  'WorkLogDescription',
                  'WorkLogDetailedDescription'
                ]
                if (typeof content !== 'object' || content === null) {
                  console.log('Data is not an object')
                  return
                }

                requiredProperties.forEach((property) => {
                  if (!content.hasOwnProperty(property)) {
                    console.log(`Data is missing required property: ${property}`)
                  }
                })

                props.onIncidentDetail(content)
              }

              if (attachment.contentType === 'application/json' && attachment.name === 'highIncidentDetailListPanel') {
                const content = JSON.parse(attachment.content)
                const requiredProperties = [
                  'Number',
                  'WorkLogNumber',
                  'WorkLogDescription',
                  'WorkLogDetailedDescription'
                ]
                if (typeof content !== 'object' || content === null) {
                  console.log('Data is not an object')
                  return
                }

                requiredProperties.forEach((property) => {
                  if (!content.hasOwnProperty(property)) {
                    console.log(`Data is missing required property: ${property}`)
                  }
                })

                props.onHighIncidentDetail(content)
              }

              if (attachment.contentType === 'application/json' && attachment.name === 'templateListFilter') {
                const content = JSON.parse(attachment.content)
                const entries = content.Entries
                if (Array.isArray(entries) && entries.length > 0) {
                  props.onTemplateList(content.Entries)
                }
              }
            }
            break

          case 'WEB_CHAT/SEND_FILES':
            // handleFileUpload(action.payload.files, dispatcher).catch(err => console.error(err));
            break
        }
      } catch (err) {
        console.log(err)
      } finally {
        return next(action)
      }
    })

    setDirectline(directLine)
    setStore(store)

    directLine.connectionStatus$.subscribe((connectionStatus) => {
      const connectionStatuses = ['UNINITIATED', 'CONNECTING', 'ONLINE', 'TOKEN_EXPIRED', 'FAILED_TO_CONNECT', 'ENDED']
      // console.log(`DirectLine connection status: ${connectionStatuses[connectionStatus]}`);
      if (connectionStatus === 4) {
        props.onShowModal()
      }

      if (connectionStatus === 2) {
        console.error('warning being generated by botframework. they are working on a fix for this https://github.com/microsoft/BotFramework-WebChat/issues')
      }
    })

    resetTimer()
    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('keydown', resetTimer)
    return () => {
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('keydown', resetTimer)
    }
  }, [])

  useEffect(() => {
    const getConversation = async (contact) => {
      return (await axios.post(`${directlineUrl}/directline/conversations`, { user: { id: contact } })).data
    }

    getConversation(contact).then((res) => {
      setConversation(res)
    })
  }, [directline])

  useEffect(() => {
    if (isConnected) {
      store.dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'webchat/join',
          value: props.startMessage ? props.startMessage : properties.startUpMessage
        }
      })
    }
  }, [isConnected])

  const activityMiddleware = () => (next) => (card) => {
    const { activity } = card

    if (activity.type === 'message' && activity.text === '/reset') {
      return (
        <div>
          <p>End of Conversation. Connection has been reset.</p>
        </div>
      )
    }

    if (activity.type === 'messageReaction') {
      console.log('beep boop')
      return false
    }

    return next(card)
  }

  return (
    <div className='web-background'>
        {directline && (
          <ReactWebChat
            directLine={directline}
            store={store}
            userID={contact}
            username={props.formData.name}
            styleOptions={props.styleOptions}
            activityStatusMiddleware={activityMiddleware}
          />
        )}
    </div>
  )
}

export default WebChat
