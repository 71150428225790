// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ssT2mHUTtk2AARgxIkLg {}`, "",{"version":3,"sources":["webpack://./src/components/AdminNotifications/AdminNotifications.module.css"],"names":[],"mappings":"AAAA,uBAAqB","sourcesContent":[".AdminNotifications {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdminNotifications": `ssT2mHUTtk2AARgxIkLg`
};
export default ___CSS_LOADER_EXPORT___;
