import React, { useContext, useState } from 'react'
import { styled, useTheme } from '@mui/system'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import Button from '@mui/material/Button'
import InboxIcon from '@mui/icons-material/Inbox'
import ConfigContext from '../ConfigContext/Config'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import CampaignsIcon from '@mui/icons-material/Campaign'
import AdminIcon from '@mui/icons-material/AdminPanelSettings'
import { useNavigate } from 'react-router-dom'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { UserContext } from '../Auth/Auth'
import { Collapse } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
  minHeight: 56,
  '@media (min-width:0px) and (orientation: landscape)': {
    minHeight: 48
  },
  '@media (min-width:600px)': {
    minHeight: 64
  }
}))

export default function PersistentDrawerLeft({ open, handleDrawerClose, handleDrawerOpen }) {
  const theme = useTheme()
  const { properties } = useContext(ConfigContext)
  const { session } = useContext(UserContext)
  const [openAdmin, setOpenAdmin] = useState(false)

  const companyLogoImageModule = require(`../../assets/${properties.id}/logo.png`)
  const companyLogoImage = companyLogoImageModule.default
  const navigate = useNavigate()
  const allowedEmails = ['mkateko.masilane@bcx.co.za', 'verushka.soobramoney@bcx.co.za']
  let isAdmin: boolean = false;
  if (session && session.user) {
    isAdmin = allowedEmails.includes(session.user.InternetEmail) || allowedEmails.includes(session.user.CorporateEmail)
  }

  const menuItems = [
    { text: 'Agent Assist', icon: <SupportAgentIcon />, path: '/' },
    // { text: 'Campaigns', icon: <CampaignsIcon />, path: '/campaign' },
    ...(isAdmin
      ? [{
        text: 'Admin',
        icon: <AdminIcon />,
        path: '/admin',
        subItems: [
          { text: 'Notifications', path: '/admin/notifications' }
        ]
      }]
      : [])
  ]

  const handleClickAdmin = () => {
    setOpenAdmin(!openAdmin)
  }

  const handleLogOut = () => {
    navigate("/logout");
  }

  if (!session || !session.user) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', height: '60px', overflow: 'hidden' }}>
      <MuiAppBar
        position="fixed"
        sx={{
          backgroundColor: '#36454F',
          height: '64px',
          transition: (theme) => theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            BCX
          </Typography>
        </Toolbar>
      </MuiAppBar>

      <MuiDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
          <img src={companyLogoImage} alt="Company Logo" style={{ width: 120, height: 60, marginBottom: '16px' }} />
          <Typography variant="h6">{session.user.Name + ' ' + session.user.Surname}</Typography>
          <Typography variant="body2" color="text.secondary">{session.user.InternetEmail || session.user.CorporateEmail}</Typography>
          <Button variant="outlined" startIcon={<LogoutIcon />} sx={{ mt: 2 }} onClick={handleLogOut}>Logout</Button>
        </Box>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <div key={index}>
              <ListItem button onClick={item.text === 'Admin' ? handleClickAdmin : () => { navigate(item.path) }}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
                {item.text === 'Admin' ? (openAdmin ? <ExpandLess /> : <ExpandMore />) : null}
              </ListItem>
              {(item.subItems != null) && (
                <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem, subIndex) => (
                      <ListItem button key={subIndex} onClick={() => { navigate(subItem.path) }} sx={{ pl: 4 }}>
                        <ListItemText primary={subItem.text} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </MuiDrawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
        <DrawerHeader />
      </Box>
    </Box>
  )
}
