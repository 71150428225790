import React, { createContext, useEffect, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import configContext from '../ConfigContext/Config'
import axios from 'axios'
import Loader from '../loader/loader'

interface Agent {
  Active: boolean
  Company: string
  CorporateEmail: string
  CorporateID: string
  InternetEmail: string
  JobTitle: string
  Name: string
  Organization: string
  PersonID: string
  PhoneBusiness: string
  PhoneHome: string | null
  PhoneMobile: string
  Priority: string | null
  RemedyLoginID: string
  RequestTicket: string | null
  Site: string
  SiteID: number
  Surname: string
  VIP: boolean
}

interface Session {
  user: Agent
  caseId: string
  message: string
  sessionId: string
}

const UserContext = createContext({
  session: null,
  setSession: (session: Session | null) => { },
  logout: () => {}
})

const Auth = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // On component mount, check if there is a user in local storage
    setLoading(true)
    const storedUser = localStorage.getItem('session')
    const storedTimestamp = localStorage.getItem('timestamp')

    if (storedUser && storedTimestamp) {
      const currentTime = new Date().getTime()
      const expiryTime = new Date(JSON.parse(storedTimestamp)).getTime() + 24 * 60 * 60 * 1000 // 24 hours from when the user was stored

      // If the current time is greater than the expiry time, remove the user from local storage
      if (currentTime > expiryTime) {
        localStorage.removeItem('session')
        localStorage.removeItem('timestamp')
      } else {
        // Otherwise, set the user state to the stored user
        setSession(JSON.parse(storedUser))
      }
    }
    setLoading(false)
  }, [])

  const contextValue = {
    session,
    setSession: (newSession: Session | null) => {
      setSession(newSession)

      if (newSession != null) {
        // If a new user is being set, store the user and the current timestamp in local storage
        localStorage.setItem('session', JSON.stringify(newSession))
        localStorage.setItem('timestamp', JSON.stringify(new Date().getTime()))
      } else {
        // If the user is being unset, remove the user and timestamp from local storage
        localStorage.removeItem('session')
        localStorage.removeItem('timestamp')
      }
    },
    logout: () => {
      setSession(null)
      // Remove the user and timestamp from local storage
      localStorage.removeItem('session')
      localStorage.removeItem('timestamp')
    }
  }

  if (loading) {
    return <Loader show={loading} />
  }

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  )
}

export { Auth, UserContext }
