import React, { useState, useContext } from 'react'
import { UserContext } from '../Auth/Auth'
import configContext from '../ConfigContext/Config'
import _ from 'lodash'

import 'react-tabs/style/react-tabs.css'

import Modal from '../modal/modal'

// css
import './home.css'
import TimeoutModal from '../modal/timeout-modal'
import ReactWebChatPage from '../ReactWebChatPage/ReactWebChatPage'

function Home () {
  const { features, styles, cognito, properties } = useContext(configContext)
  const { session } = useContext(UserContext)

  const [formSubmitted, setSubmit] = useState(false)
  const [otpSuccessful, setOTP] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showTimeoutModal, setShowTimeoutModal] = useState(false)
  // const { logout } = useContext(UserContext);
  const botImageModule = require(`../../assets/${properties.id}/bot.png`)
  const botImage = botImageModule.default
  const botStyles = {
    // bot icon
    botAvatarInitials: styles.botAvatarInitials,
    accent: '#00809d',
    botAvatarBackgroundColor: '#FFFFFF',
    botAvatarImage: botImage,
    hideSendBox: true,

    // bot messages
    // bubbleBorderColor: '#BD302C',
    // bubbleBackground: '#f2f2f2',
    bubbleBorderRadius: 20,

    // user messages
    bubbleFromUserBackground: '#ffffff',
    // bubbleFromUserBorderColor: '#BD302C',
    // bubbleFromUserBorderStyle: 'solid',
    // bubbleFromUserBorderWidth: 1,
    bubbleFromUserBorderRadius: 20,
    sendBoxButtonColorOnHover: '#BD302C',

    // adaptive cards
    cardEmphasisBackgroundColor: '#c61f48',
    cardPushButtonBackgroundColor: 'black',
    cardPushButtonTextColor: 'White'

  }

  // form submit functions

  const onhandleShowModal = () => {
    setShowModal(true)
  }

  const onhandleShowTimeoutModal = () => {
    setShowTimeoutModal(true)
  }

  const onHandleLogOut = async () => {
    console.log('logging out')
    // logout();
    setSubmit(false)
    setOTP(false)
    setShowModal(false)
  }

  const onCloseTimeoutModal = () => {
    setShowTimeoutModal(false)
  }

  const onRefreshSession = () => {
    setShowModal(false)
    window.location.reload()
  }

  const formData = {
    name: session.user.Name + ' ' + session.user.Surname,
    contact: session.user.InternetEmail || session.user.CorporateEmail,
    type: 'email'
  }
  const message = session.message

  console.log(session.user)

  return (
    <React.Fragment>
      <ReactWebChatPage
        botStyles={botStyles}
        formData={formData}
        message={message}
        onShowModal={onhandleShowModal}
        onShowTimeoutModal={onhandleShowTimeoutModal} />

      <Modal show={showModal} onRefreshSession={onRefreshSession} onLogout={onHandleLogOut} />
      <TimeoutModal show={showTimeoutModal} onClose={onCloseTimeoutModal} onLogout={onHandleLogOut} />
    </React.Fragment>
  )
}

export default Home
