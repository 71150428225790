import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../Auth/Auth';

const AdminRoute = ({ children }) => {
  const { session } = useContext(UserContext);
  const allowedEmails = ['mkateko.masilane@bcx.co.za', 'verushka.soobramoney@bcx.co.za'];
  let isAdmin = false;
  if (session && session.user) {
    isAdmin = allowedEmails.includes(session.user.InternetEmail) || allowedEmails.includes(session.user.CorporateEmail);
  }
  const navigate = useNavigate();

  useEffect(() => {
    // If the user is not an admin, redirect them to a different page
    if (!isAdmin) {
      console.log('deny access');
      navigate('/'); // Navigate to the home page or any other page
    }
  }, [isAdmin, navigate]); // Dependencies to watch for changes

  // If the user is an admin, render the children components
  return children;
};

export default AdminRoute;
