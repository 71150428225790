import { Navigate } from 'react-router-dom'
import React, { useContext } from 'react'
import { UserContext } from '../Auth/Auth'

const PrivateComponent = ({ children }) => {
  const { session } = useContext(UserContext)

  return session ? children : null
}

export default PrivateComponent
