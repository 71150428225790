import axios from 'axios'
import React, { type FC, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ConfigContext from '../ConfigContext/Config'
import { UserContext } from '../Auth/Auth'
import { Box, Button, Typography } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { styled } from '@mui/system'

interface LoginProps { }

const Login: FC<LoginProps> = () => {
  const { cognito, properties, features } = useContext(ConfigContext)
  const { setSession } = useContext(UserContext)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const ErrorBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(3),
    textAlign: 'center'
  }))

  const ErrorIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
    fontSize: '5rem',
    color: 'red',
    marginBottom: theme.spacing(2)
  }))

  const decryptToken = async (encryptedToken: string): Promise<string> => {
    try {
      const env = process.env.NODE_ENV === 'production' ? 'prod' : 'stage'
      const response = await axios.post(`https://hapi.xiva-react-chatbot-af.${env}.xibsolutions.com/dynamo/decrypt`, { encryptedToken })
      return response.data
    } catch (error) {
      throw error
    }
  }

  const onHandleDefualtLogon = () => {
    const data = {
      "caseId": "QmuRb4",
      "message": "menu",
      "sessionId": "d1e1b9a3-8bde-44de-b7e7-30495ef2b0d5",
      "iat": 1693597056,
      "agentDetails": [
          {
              "Company": "Business Connexion (PTY) LTD",
              "Organization": null,
              "SiteID": 1573,
              "Site": "BCX Centurion Block 2 West Wing",
              "PersonID": "PPL000050094125",
              "CorporateID": "00151885",
              "Name": "Default",
              "Surname": "User",
              "JobTitle": "Service Desk Manager: CFMC",
              "PhoneMobile": "0765050068",
              "PhoneBusiness": "01264243830765050068",
              "PhoneHome": null,
              "CorporateEmail": "default.user@bcx.co.za",
              "InternetEmail": "default.user@bcx.co.za",
              "RemedyLoginID": "default.user@bcx.co.za",
              "VIP": false,
              "Active": true,
              "RequestTicket": null,
              "Priority": null
          }
      ]
  }
    setSession({ user: data.agentDetails[0], caseId: data.caseId, message: data.message, sessionId: data.sessionId });
    navigate('/home');
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const token = queryParams.get('token')
        const encryptedToken = decodeURIComponent(token)
        const data: any = await decryptToken(encryptedToken)
        if (data) {
          const agent = data.agentDetails[0]
          if (agent) {
            console.log(agent)
            setSession({ user: agent, caseId: data.caseId, message: data.message, sessionId: data.sessionId })
            navigate('/home')
          }
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error(error)
      }
    }

    fetchData()
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <ErrorBox>
      <ErrorIcon />
      <Typography variant="h4" gutterBottom>
        Token not found, would you like to continue as a default user?
      </Typography>
      <Button onClick={onHandleDefualtLogon}>Yes</Button>
     </ErrorBox>
  )
}

export default Login
