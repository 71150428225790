import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../Auth/Auth';

const LogoutPage = () => {
  const { logout } = useContext(UserContext);

  useEffect(() => {
    logout();
  }, [])

  return (
    <div className="logout-page">
      <h1>You have been logged out</h1>
      <p>Thank you for using our application. We hope to see you again soon!</p>
    </div>
  );
};

export default LogoutPage;
