import axios from 'axios'

const env = process.env.NODE_ENV === 'production' ? 'prod' : 'stage'
const dynamoDomain = `https://hapi.xiva-react-chatbot-af.${env}.xibsolutions.com`

export const fetchAllNotifications = async (pk: string): Promise<any> => {
  return await axios.get(`${dynamoDomain}/dynamo/notifications/${pk}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error('An error occurred while fetching the notifications:', error)
      throw error
    })
}

export const createNotification = async (notification: object): Promise<any> => {
  return await axios.post(`${dynamoDomain}/dynamo/notifications`, notification)
}

export const deleteNotification = async (pk: string, sk: string): Promise<any> => {
  return await axios.delete(`${dynamoDomain}/dynamo/notifications/${pk}/${sk}`)
}
