import { Navigate } from 'react-router-dom'
import React, { useContext } from 'react'
import { UserContext } from '../Auth/Auth'

const PrivateRoute = ({ children }) => {
  const { session } = useContext(UserContext)

  return session ? children : <Navigate to="/login" />
}

export default PrivateRoute
