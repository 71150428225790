import React, { useContext, useEffect, useReducer, useState } from 'react'
import Home from './components/home/home'
import { Auth, UserContext } from './components/Auth/Auth'
import './App.css'
import ConfigContext, { ConfigProvider } from './components/ConfigContext/Config'
import ErrorBoundary from './components/utility/ErrorBoundary'
import { BrowserRouter as Router, Route, Routes, useNavigate, useRoutes, Navigate } from 'react-router-dom'
import Campaign from './components/Campaign/Campaign'
import Admin from './components/Admin/Admin'
import Sidebar from './components/Sidebar/Sidebar'
import { Backdrop, Box } from '@mui/material'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Login from './components/Login/Login'
import AdminNotifications from './components/AdminNotifications/AdminNotifications'
import AdminRoute from './components/PrivateRoute/AdminRoute'
import LogoutPage from './components/Logout/Logout'
import PrivateComponent from './components/PrivateComponent/PrivateComponent'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return { ...state, formData: action.payload }
    case 'SET_MESSAGE':
      return { ...state, formData: action.payload }
    default:
      return state
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, {
    formData: null,
    setMessage: ''
  })
  const { formData, message } = state
  const { features, properties, styles } = useContext(ConfigContext)
  const { session } = useContext(UserContext);
  const [open, setOpen] = useState(false)

  document.documentElement.style.setProperty('--main-tertiary-color', styles.mainTertiaryColor)
  document.documentElement.style.setProperty('--login-background', styles.login.background)
  document.documentElement.style.setProperty('--chat-background', styles.chatBackground)
  document.documentElement.style.setProperty('--main-sub-color', styles.mainSubColor)
  document.documentElement.style.setProperty('--header-color', styles.header.background)
  document.documentElement.style.setProperty('--header-card-color', styles.header.cardBackground)
  document.documentElement.style.setProperty('--header-card-text-color', styles.header.cardTextColor)
  document.documentElement.style.setProperty('--login-screen', styles.login.screen)
  document.documentElement.style.setProperty('--login-shape1', styles.login.shape1)
  document.documentElement.style.setProperty('--login-shape2', styles.login.shape2)
  document.documentElement.style.setProperty('--login-shape3', styles.login.shape3)
  document.documentElement.style.setProperty('--login-shape4', styles.login.shape4)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleFormSubmit = () => {
    console.log('trying to login')
  }

  const handleError = () => {
    console.log('trying to login')
  }

  return (
    <div className="App">
      <div className="main-container">
        <Auth>
          <Router>
            <PrivateComponent>
              <Sidebar open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
              <Backdrop open={open} onClick={handleDrawerClose} style={{ zIndex: 1000 }} />
            </PrivateComponent>
            <Box sx={{ height: '100%' }}>
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="logout" element={<LogoutPage />} />
                <Route path="login" element={<Login />} />
                <Route path="home" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="campaign" element={<PrivateRoute><Campaign /></PrivateRoute>} />
                <Route path="admin" element={<AdminRoute><Admin /></AdminRoute>}>
                  <Route path="notifications" element={<AdminRoute><AdminNotifications /></AdminRoute>} />
                </Route>
              </Routes>
            </Box>
          </Router>
        </Auth>
      </div>
    </div>
  )
}

export default App
