// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.main-container {
  transform: scale(0.8);
  transform-origin: top left;
  width: 125%; 
  min-height: 100%; 
  overflow-x: hidden;
}

.App {
  height: 100%;
}

/* .main-container {
  flex-grow: 1;
} */

@media only screen and (max-width: 600px) {
    .App {
      flex-direction: column;
      overflow: hidden;
    }
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,0BAA0B;EAC1B,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;;GAEG;;AAEH;IACI;MACE,sBAAsB;MACtB,gBAAgB;IAClB;AACJ","sourcesContent":["*,\r\n*::before,\r\n*::after {\r\n  box-sizing: border-box;\r\n}\r\n\r\nhtml, body {\r\n  height: 100%;\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.main-container {\r\n  transform: scale(0.8);\r\n  transform-origin: top left;\r\n  width: 125%; \r\n  min-height: 100%; \r\n  overflow-x: hidden;\r\n}\r\n\r\n.App {\r\n  height: 100%;\r\n}\r\n\r\n/* .main-container {\r\n  flex-grow: 1;\r\n} */\r\n\r\n@media only screen and (max-width: 600px) {\r\n    .App {\r\n      flex-direction: column;\r\n      overflow: hidden;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
