import React, { type FC, useState } from 'react'
import { Outlet } from 'react-router-dom'

interface AdminProps {}

const Admin: FC<AdminProps> = () => {
  return (
    <>
    <Outlet />
    </>
  )
}

export default Admin
